import { default as _91_46_46_46slug_93ekHJibshQXMeta } from "C:/code/treqit/docs/pages/[...slug].vue?macro=true";
import { default as indexCi1QRrcycpMeta } from "C:/code/treqit/docs/pages/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93ekHJibshQXMeta?.name ?? "slug",
    path: _91_46_46_46slug_93ekHJibshQXMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ekHJibshQXMeta || {},
    alias: _91_46_46_46slug_93ekHJibshQXMeta?.alias || [],
    redirect: _91_46_46_46slug_93ekHJibshQXMeta?.redirect,
    component: () => import("C:/code/treqit/docs/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexCi1QRrcycpMeta?.name ?? "index",
    path: indexCi1QRrcycpMeta?.path ?? "/",
    meta: indexCi1QRrcycpMeta || {},
    alias: indexCi1QRrcycpMeta?.alias || [],
    redirect: indexCi1QRrcycpMeta?.redirect,
    component: () => import("C:/code/treqit/docs/pages/index.vue").then(m => m.default || m)
  }
]